<template>
  <q-page class="q-pt-none q-pb-xxl">
    <div class="container main-container q-pt-md q-pb-sm">
      <q-no-ssr>
        <UserBlacklist v-if="customer.isBlocked" />
      </q-no-ssr>

      <!-- <CategorySlider v-if="false && showTrendingCategory" /> -->
      <!-- Collection Begin -->

      <div class="container-fluid" v-show="loading">
        <div class="row">
          <div class="col-12 relative-position">
            <q-skeleton type="rect" :height="isMobile ? '320px' : '560px'" />
            <div
              class="absolute-center z-9 bg-white r-12"
              :style="isMobile ? 'width: 240px' : 'width: 320px'"
            >
              <q-card flat class="q-width q-px-md q-py-lg r-12 s-1">
                <q-card-section class="q-pa-none">
                  <q-skeleton
                    type="text"
                    width="80%"
                    height="30px"
                    class="text-caption q-mt-sm q-mx-auto"
                  />
                  <q-skeleton
                    type="text"
                    width="30%"
                    height="30px"
                    class="text-caption q-mx-auto"
                  />
                </q-card-section>

                <q-card-actions class="q-pt-md q-pl-none q-pr-none">
                  <q-skeleton width="160px" type="QBtn" class="q-mx-auto" />
                </q-card-actions>
              </q-card>
            </div>
            <div class="absolute left-middle z-9">
              <q-skeleton
                type="circle"
                width="40px"
                height="40px"
                class="bg-white q-ma-md s-1"
              />
            </div>
            <div class="absolute right-middle z-9">
              <q-skeleton
                type="circle"
                width="40px"
                height="40px"
                class="bg-white q-ma-md s-1"
              />
            </div>
            <div class="absolute-bottom z-9">
              <div class="row q-col-gutter-md justify-center q-mb-md">
                <q-skeleton
                  type="circle"
                  width="10px"
                  height="10px"
                  class="bg-white q-ma-sm s-1"
                />
                <q-skeleton
                  type="circle"
                  width="10px"
                  height="10px"
                  class="bg-white q-ma-sm s-1"
                />
                <q-skeleton
                  type="circle"
                  width="10px"
                  height="10px"
                  class="bg-white q-ma-sm s-1"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div
              class="q-product-slider q-product-slider-loader"
              v-for="n in 3"
              :key="n"
            >
              <div class="row q-col-gutter-md q-mb-sm">
                <div class="col-12">
                  <q-skeleton
                    type="rect"
                    width="200px"
                    height="20px"
                    class="q-mb-md"
                  />
                </div>
                <div class="col" v-for="n in 7" :key="n">
                  <q-card flat bordered class="q-width q-pa-sm">
                    <q-skeleton width="100%" height="180px" class="q-mb-md" />
                    <q-card-section
                      class="row no-wrap q-pt-sm q-pl-none q-pr-none q-pb-none"
                    >
                      <div class="col">
                        <q-skeleton width="60%" height="15px" />
                      </div>
                      <div class="col-1">
                        <q-skeleton width="15px" height="15px" />
                      </div>
                    </q-card-section>
                    <q-card-section
                      class="q-pt-sm q-pl-none q-pr-none q-pb-none"
                    >
                      <q-skeleton
                        type="text"
                        width="100%"
                        class="text-caption q-mt-sm"
                      />
                      <q-skeleton
                        type="text"
                        width="30%"
                        class="text-caption"
                      />
                    </q-card-section>

                    <q-card-section
                      class="q-pt-none q-pl-none q-pr-none q-pb-none"
                    >
                      <q-skeleton width="40%" height="15px" class="q-mt-md" />
                    </q-card-section>

                    <q-card-actions
                      class="q-pt-md q-pl-none q-pr-none q-pb-none"
                    >
                      <q-skeleton width="100%" type="QBtn" />
                    </q-card-actions>
                  </q-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <q-no-ssr>
        <SplitSection
          v-for="(section, key, index) in visibleSections"
          :key="`${key}-${index}-${layerItemKey}`"
          :section="section"
          :hideComponent="hideComponent"
        />

        <EmptyComponent
          v-if="!loading && Object.keys(visibleSections).length == 0"
          image="images/empty-product.png"
        >
          <p>Oops! Nothing here.</p>
        </EmptyComponent>
      </q-no-ssr>
    </div>

    <!-- <q-infinite-scroll
      v-if="!loading"
      @load="fetchNext"
      :initial-index="1"
      :offset="3000"
      ref="infiniteScroll"
    >
      <div
        v-for="(collection, index) in collections"
        :key="collection.collectionId + '-' + index"
      >
        <div class="row items-start q-product-slider">
          <div class="col-12">
            <h2 class="text-h5 q-mt-auto">
              {{ collection.collectionName }}
              <router-link
                v-if="collection.products?.length > productsInCollectionRow"
                class="link-primaryOnBody text-underline"
                :to="{
                  name: 'Collection',
                  params: { seoName: collection.seoName },
                }"
              >
                See all
              </router-link>
            </h2>
          </div>

          <Swiper :products="collection.products" />
        </div>
        <q-separator v-if="index != collections.length - 1" />
      </div>

      <template v-slot:loading>
        <LoadMoreSpinner v-show="!loading" />
      </template>
    </q-infinite-scroll> -->
    <!-- Collection End -->

    <!-- Skeleton Loader -->
    <!-- <CollectionSkeleton :collectionSize="2" v-show="loading" /> -->
    <!-- Loader -->

    <!-- Empty Component -->
    <!-- <EmptyComponent
      v-if="!loading && collections.length == 0"
      image="images/empty-product.png"
    >
      <p>No products found for the location.</p>
    </EmptyComponent> -->
  </q-page>
</template>

<script>
// import CategorySlider from 'components/CategorySlider.vue'
// import ImageSlider from 'components/home-page-components/ImageSlider'
// import Collection from 'components/home-page-components/Collection'
// import CollectionSkeleton from 'components/CollectionSkeleton'
// import Swiper from 'components/Swiper'
import { mapGetters } from 'vuex'
import UserBlacklist from 'components/common/UserBlacklist.vue'
import {
  setAVSessionID,
  removeAVSessionID,
  tempCacheStorage,
} from 'src/boot/axios'

export default {
  name: 'PageHome',
  // preFetch({ store, currentRoute }) {
  //   let tenantFound = store.getters['common/tenantFound'],
  //     storeIsActive = store.getters['common/storeIsActive'],
  //     { sessionId } = currentRoute.query

  //   if (tenantFound && storeIsActive && !sessionId) {
  //     let promise = store.dispatch('homepage/getHomePageTemplate')
  //     // let promise = store.dispatch('collection/getCollections', 1)

  //     if (process.env.SERVER) return promise
  //   }
  // },
  components: {
    // ImageSlider,
    // Collection,
    // CollectionSkeleton,
    // CategorySlider,
    // Swiper,
    UserBlacklist,
  },
  meta() {
    return {
      title: this.pageTitle,
      ...this.commonMeta,
      meta: {
        title: {
          name: 'title',
          content: this.pageTitle,
        },
        description: {
          name: 'description',
          content: this.pageTitle,
        },
        ogTitle: {
          property: 'og:title',
          content: this.pageTitle,
        },
        ogDescription: {
          property: 'og:description',
          content: this.pageTitle,
        },
        ogUrl: {
          property: 'og:url',
          content: this.currentRoutePath,
        },
      },
      link: {
        canonical: {
          rel: 'canonical',
          href: this.currentRoutePath,
        },
      },
    }
  },
  data() {
    return {
      activated: false,
      layerItemKey: 0,
      pageTitle: 'Online Shopping',
    }
  },
  computed: {
    ...mapGetters('homepage', ['loading', 'homePageData', 'hideComponent']),
    // ...mapGetters('collection', [
    //   // 'loading',
    //   'collections',
    // ]),
    ...mapGetters('persisted', [
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
      // 'showEAIVPage',
    ]),
    ...mapGetters('ageVerification', [
      'isVerificationFail',
      'showVeirificationPopup',
    ]),
    eaivSessionId() {
      return this.$route.query?.sessionId
    },
    visibleSections() {
      let layerJson = this.homePageData?.layerJson
      let visibleSections = {}

      for (const unId in layerJson) {
        if (layerJson[unId].visible) {
          visibleSections[unId] = layerJson[unId]
        }
      }

      return visibleSections
    },
  },
  created() {
    this.$root.$on('eaivVerificationSuccess', this.onEaivSuccess)
    this.$root.$on('eaivVerificationFailed', this.onEaivFail)
  },
  mounted() {
    let tenantFound = this.$store.getters['common/tenantFound'],
      storeIsActive = this.$store.getters['common/storeIsActive'],
      { sessionId } = this.$route.query

    if (tenantFound && storeIsActive && !sessionId) {
      this.$store.dispatch('homepage/getHomePageTemplate')
    }

    // Browse the products on successful verification
    // if (
    //   !this.isCordova &&
    //   !this.showEAIVPage &&
    //   this.businessConfigurations?.isStoreAgeRestricted &&
    //   this.businessConfigurations?.enableAgeRestrictedBrowsing &&
    //   !this.eaivSessionId &&
    //   this.eaivPersistedVerificationSuccess
    //   // || !this.collections.length
    // ) {
    //   let tenantFound = this.$store.getters['common/tenantFound'],
    //     storeIsActive = this.$store.getters['common/storeIsActive']

    //   if (tenantFound && storeIsActive) {
    //     this.$root.$emit('scroll-to-position')
    //     this.$store.dispatch('homepage/getHomePageTemplate')
    //     // this.$store.dispatch('collection/getCollections', 1)
    //   }
    // }
  },
  activated() {
    this.activated = true
  },
  deactivated() {
    if (!this.eaivSessionId) {
      // this.$store.commit('collection/RESET_COLLECTIONS')
      this.activated = false
    }
  },
  methods: {
    // async fetchNext(index, done) {
    //   let hasNext = await this.$store.dispatch(
    //     'collection/getCollections',
    //     index
    //   )
    //   done(!hasNext)
    // },
    removeQueryParams(eaivSessionId, isSuccess) {
      if (isSuccess) setAVSessionID(eaivSessionId)
      else {
        this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
        removeAVSessionID()
      }

      this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', isSuccess)
      // remove sessionId from URL
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.sessionId
      this.$router.replace({ query, params }).catch(() => {})
    },
    async onEaivSuccess({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Home') {
        if (!eaivSessionId || !routeName) return
        if (!isPopup) this.removeQueryParams(eaivSessionId, true)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.showSuccess('Age Verified Successfully.')
        }, 500)

        if (this.isCordova) {
          let tenantFound = this.$store.getters['common/tenantFound'],
            storeIsActive = this.$store.getters['common/storeIsActive']
          if (tenantFound && storeIsActive) {
            this.layerItemKey++ // Re-render components of home page templates
            this.$root.$emit('scroll-to-position')
            await this.$store.dispatch('homepage/getHomePageTemplate')
            // await this.$store.dispatch('collection/getCollections', 1)
          }
        } else if (isPopup) {
          let tenantFound = this.$store.getters['common/tenantFound'],
            storeIsActive = this.$store.getters['common/storeIsActive']
          if (tenantFound && storeIsActive) {
            this.layerItemKey++ // Re-render components of home page templates
            // tempCacheStorage.clear()
            this.$root.$emit('scroll-to-position')
            await this.$store.dispatch('homepage/getHomePageTemplate')
            // await this.$store.dispatch('collection/getCollections', 1)
          }
        }
      }
    },
    async onEaivFail({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Home') {
        if (!eaivSessionId || !routeName) return
        if (!isPopup) this.removeQueryParams(eaivSessionId, false)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.changeDialogState({
            dialog: 'VerificationFailDialog',
            val: true,
            properties: {},
          })
        }, 500)

        if (this.isCordova) {
          let tenantFound = this.$store.getters['common/tenantFound'],
            storeIsActive = this.$store.getters['common/storeIsActive']
          if (tenantFound && storeIsActive) {
            this.$root.$emit('scroll-to-position')
            await this.$store.dispatch('homepage/getHomePageTemplate')
            // await this.$store.dispatch('collection/getCollections', 1)
          }
        } else if (isPopup) {
          let tenantFound = this.$store.getters['common/tenantFound'],
            storeIsActive = this.$store.getters['common/storeIsActive']
          if (tenantFound && storeIsActive) {
            tempCacheStorage.clear()
            this.$root.$emit('scroll-to-position')
            await this.$store.dispatch('homepage/getHomePageTemplate')
            // await this.$store.dispatch('collection/getCollections', 1)
          }
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@media (max-width: 1023px) {
  .q-page {
    padding: 20px 0px;
    &.q-pb-xxl {
      padding-bottom: 0;
      .show-age-verification & {
        padding-bottom: 60px;
      }
    }
    &.q-pt-none {
      padding-top: 0;
    }

    &.q-pb-none {
      padding-top: 0;
    }
  }
}
</style>
