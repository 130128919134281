import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=7454a779&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=7454a779&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7454a779",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QNoSsr from 'quasar/src/components/no-ssr/QNoSsr.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QNoSsr,QSkeleton,QCard,QCardSection,QCardActions,QInfiniteScroll,QSeparator});
