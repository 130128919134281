<template>
  <q-dialog v-model="showDialog" @hide="hide" persistent animated>
    <q-card class="block-user-popup">
      <q-card-section>
        <SvgIcon icon="lock" />
        <div class="text-h6">Your account has been disabled.</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        Please contact our support team to get your account back!
        <p
          ><a
            href="mailto:support@ftxsupport.com"
            class="text-primary no-underline"
          >
            support@ftxsupport.com
          </a></p
        >
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          flat
          label="OK"
          color="primary"
          v-close-popup
          class="q-ma-sm q-py-sm q-px-md radius-lg q-btn--unelevated q-btn--rectangle q-btn--rounded bg-primary text-white q-btn--actionable q-focusable q-hoverable q-btn--wrap"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
export default {
  name: 'UserBlacklist',
  data() {
    return {
      dialog: 'UserBlacklist',
      loading: false,
      showDialog: true,
    }
  },
  methods: {
    hide() {
      this.showDialog = false
      this.performLogout()
    },
  },
}
</script>
